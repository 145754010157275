.info-overlay-container{
  position: absolute;
  width:100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
  z-index: 999;
  overflow-y: scroll;
  .close-wrapper{
    position: absolute;
    top:6px;
    right:6px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .icon{
      color: #ffffff;
      width:33px;
      height: 33px;
    }
  }
  .info-overlay-title{
    font-size: 45px;
    color: #ffffff;
    margin:39px 12px 21px 12px;
    max-width:900px;
  }
  .info-overlay-desc{
    font-size: 15px;
    color: #ffffff;
    margin:21px 12px;
    max-width:900px;
    span{
      display: block;
      margin:12px 0;
    }
  }
  .info-overlay-legend{
    color: #ffffff;
    margin:21px 12px;
    .legend-header{
      font-size: 21px;
    }
    .info-overlay-legend-item{
      display: flex;
      flex-direction: column;
      margin:21px 0;
      max-width:900px;
      .legend-item-top{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom:6px;
        .icon{
          color:#ffffff;
          width:24px;
          height: 24px;
        }
        .legend-item-title{
          font-size: 18px;
          margin-left: 21px;
        }
      }
      .legend-item-bottom{
        font-size: 15px;
        display: flex;
        .social-links{
          display: flex;
          margin-top:9px;
          .icon{
            color:#ffffff;
            width:33px;
            height: 33px;
          }
          .icon-logo-facebook{
            margin-left:12px;
          }
          .icon-logo-instagram{
            margin-left:12px;
          }
        }
      }
    }
  }
  .info-overlay-partners{
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 99px;
    .partners-header{
      font-size: 18px;
      margin-bottom: 18px;
    }
    .partners-logos{
      display: flex;
      @media only screen 
        and (min-device-width: 320px) 
        and (max-device-width: 480px)
        and (-webkit-min-device-pixel-ratio: 2)
        and (orientation: portrait) {
        flex-direction: column;
        .image-wrapper{
          margin:24px 0;
        }
      }
      @media only screen 
        and (max-device-width:769px)
        and (orientation: portrait) {
        img{
          max-width: 121px !important;
        }
      }
      img{
        max-width: 199px;
        max-height:95px;
        margin:0 12px;
      }
    }
  }
  .app-version{
    position: fixed;
    bottom:12px;
    right:12px;
    color: #ffffff;
  }
}