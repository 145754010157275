@import '../../core/colours';

.edit-mode-wrapper{
  position: relative;
  height: calc(100% - 48px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  .edit-mode-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom:1px solid #c1c1c1;
    background-color: #dadada;
    position: relative;
    height:120px;
    .icon{
      width:33px;
      height: 33px;
    }
    &:hover{
      background-color: #c1c1c1;
      cursor: pointer;
    }
    &.has-image{
      height: auto;
      background-color: #ffffff;
      img{
        max-width:100%;
      }
      .icon{
        color: #ED6969;
        position: absolute;
        top:3px;
        right:3px;
      }
    }
  }
  .edit-mode-input{
    display: flex;
    flex-direction: column;
    padding:6px 3px;
    > span{
      font-size: 15px;
      margin-bottom: 6px;
    }
    input{
      padding:6px 3px;
      border:1px solid #c1c1c1;
    }
    textarea{
      height: 220px;
      border:1px solid #c1c1c1;
      font-family: 'Helvetica', 'Arial', sans-serif;
    }
  }
  button{
    padding:6px 12px;
    margin:5px 0;
    outline:none;
    cursor: pointer;
    font-size: 21px;
    width:98%;
    margin: 0 1%;
    font-family: 'Raleway', sans-serif;
    border:1px solid #ED6969;
    color: #ED6969;
    background-color: rgba(#ffffff,0.8);
    &:hover{
      color:#ffffff;
      background-color: rgba(#ED6969,0.8);
    }
  }
}