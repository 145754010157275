.App{
  position: relative;
  height: 100%;
  width:100%;
  overflow: hidden;
  touch-action: none;
  &.mouse-move{
    cursor: grabbing;
  }
  .settings{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:6px;
    top:12px;
    right:12px;
    background-color: #f4f4f4;
    width:33px;
    height: 33px;
    border-radius: 33px;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.29);
    border: 1px solid #c1c1c1;
    .icon{
      width:27px;
      height: 27px;
    }
  }
  .zoom-controls{
    position: absolute;
    bottom: 12px;
    right:12px;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius:9px;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.29);
    height:150px;
    justify-content: space-around;
    border: 1px solid #c1c1c1;
    z-index: 998;
    .spacer{
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 0;
      .icon{
        width:27px;
        height: 27px;
      }
    }
    .controls{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding:6px;
      &:hover:first-child{
        border-top-right-radius:9px;
        border-top-left-radius:9px;
        background-color: #d2d2d2;
      }
      &:hover:last-child{
        border-bottom-right-radius:9px;
        border-bottom-left-radius:9px;
        background-color: #d2d2d2;
      }
      .icon{
        width:27px;
        height: 27px;
      }
      &:hover{
        .icon{
          color: #202020;
        }
      }
    }
  }
  .opacity-controls{
    position: absolute;
    bottom: 12px;
    right:90px;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius:9px;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.29);
    height:150px;
    justify-content: space-around;
    border: 1px solid #c1c1c1;
    z-index: 998;
    .spacer{
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 0;
      img{
        width:33px;
        height:33px;
      }
    }
    .controls{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33%;
      padding:6px;
      &:hover:first-child{
        border-top-right-radius:9px;
        border-top-left-radius:9px;
        background-color: #d2d2d2;
      }
      &:hover:last-child{
        border-bottom-right-radius:9px;
        border-bottom-left-radius:9px;
        background-color: #d2d2d2;
      }
      .icon{
        width:27px;
        height: 27px;
      }
      &:hover{
        .icon{
          color: #202020;
        }
      }
    }
  }
  .info-overlay{
    position: absolute;
    top: 12px;
    right: 12px;
    border: 1px solid #c1c1c1;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    width: 33px;
    height: 33px;
    .icon{
      width: 27px;
      height: 27px;
    }
  }
}