.map-pin{
  font-family: 'Ariel', sans-serif;
  cursor: pointer;
  z-index: 997;
  svg{
    width:33px;
    height:50px;
  }
  &.hovered{
    width:193px;
    z-index: 998;
  }
  .pin-name{
    font-size: 23px;
    font-weight: bold;
    position: absolute;
    width: 160px;
    color:#ffffff;
    text-shadow: 1px 1px #000000;
    //-webkit-text-fill-color: white; /* Will override color (regardless of order) */
    //-webkit-text-stroke-width: 1px;
    //-webkit-text-stroke-color: black;
    left: 36px;
    top: 0px;
    user-select:none;
  }
  .edit-pin-name-coordinates{
    position: absolute;
    top:-20px;
    font-size: 18px;
    background-color: #ffffff;
    padding:3px;
    user-select: text;
  }
  &.mouse-move{
    pointer-events: none;
  }
}