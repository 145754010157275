.vimeo-video-wrapper{
  position: absolute;
  top:0;
  left:0;
  z-index: 9999;
  width:100%;
  height: 100%;
  background-color: #000000;
  .icon-wrapper{
    position: absolute;
    top:12px;
    left:12px;
    z-index: 9999;
    .icon{
      color: #ffffff;
      width:33px;
      height: 33px;
    }
  }
  video{
    position: relative;
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}