.sidebar-wrapper{
  position: absolute;
  right:12px;
  top:12px;
  height:auto;
  background-color: #fff;
  border: 1px solid #c1c1c1;
  border-radius: 6px;
  padding-bottom: 12px;
  z-index: 999;
  max-height: 720px;
  overflow:hidden;
  .sidebar-header{
    height:47px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #c1c1c1;
    .icon-wrapper{
      padding:6px;
      .icon{
        width: 27px;
        height: 27px;
      }
    }
  }
}