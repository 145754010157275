.main-menu-bar{
  position: absolute;
  top:12px;
  left:12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:calc(100% - 24px);
  max-width: 320px;
  z-index: 998;
  .menu-bar-left{
    display: flex;
    justify-content: space-evenly;
    border: 1px solid #c1c1c1;
    background-color: #fff;
    border-radius: 6px;
    .menu-bar-item{
      display: flex;
      justify-content: center;
      align-items: center;
      padding:6px 15px;
      width:33px;
      height: 33px;
      .icon{
        width:27px;
        height: 27px;
      }
    }
    .active-inticator{
      position: absolute;
      bottom:6px;
      left:0;
      height: 3px;
      width:33px;
      background-color:#e94660;
      opacity: 0;
      border-radius: 6px;
      &.main{
        left:16px;
        opacity: 1;
      }
      &.layers{
        left:79px;
        opacity: 1;
      }
      &.videos{
        left:142px;
        opacity: 1;
      }
    }
  }
  .menu-bar-right{
    .menu-bar-item{
      border: 1px solid #c1c1c1;
      background-color: #fff;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding:6px;
      width:33px;
      height: 33px;
      div{
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
          width:27px;
          height: 27px;
        }
      }
    }
  }
}