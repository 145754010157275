.loading-screen-container{
  position: absolute;
  width:100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: #fff;
  background: url('../../assets/img/loading_bg.jpg') no-repeat center center fixed;
  background-size: cover;
  &.video-loaded{
    background-color: transparent;
  }
  .loader-wrapper{
    img{
      width: 90vw;
      max-width: 720px;
    }
    .loader{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5%;
      min-height: 142px;
    }
  }
  .enter-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      width: 90vw;
      max-width: 720px;
    }
    .enter-controls-top{
      position: absolute;
      top:0px;
      right:0px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .icon{
        color: #ffffff;
        width:30px;
        height: 30px;
        border:1px solid #ffffff;
        margin:0 3px;
        border-radius: 33px;
        padding:6px;
        background-color: rgba($color: #000000, $alpha: 0.1);
        &:hover{
          background-color: rgba($color: #000000, $alpha: 0.3);
        }
      }
    }
    .enter-controls-bottom{
      margin-left: 5%;
      min-height: 112px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 33px;
      button{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding:6px 12px;
        margin:5px 0;
        outline:none;
        cursor: pointer;
        font-size: 27px;
        width:80vw;
        max-width: 300px;
      }
      .enter-button{
        font-family: 'Raleway', sans-serif;
        background-color: rgba($color: #000000, $alpha: 0.1);
        border:1px solid #ffffff;
        color: #ffffff;
        //text-transform: uppercase;
        
        &:hover{
          border:1px solid #ED6969;
          color: #ED6969;
          background-color: rgba(#ffffff,0.8);
        }
      }
    }
  }
  .loading-screen-box{
    width: 300px;
    height: 300px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}