@import '../../core/colours';

.pin-data-wrapper{
  position: relative;
  height: calc(100% - 48px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  &.mouse-move{
    user-select: none;
  }
  .pin-data-image{
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom:1px solid #c1c1c1;
    background-color: #000000;
    position: relative;
    img{
      max-width: 100%;
    }
    .image-loader{
      position: absolute;
      z-index: 999;
      width:100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color:#000000;
    }
  }
  .pin-data-header{
    padding:6px;
    div{
      padding:3px 0;
    }
    .pin-data-image-credit{
      font-size: 12px;
    }
    .pin-data-name{
      font-size: 21px;
    }
    .pin-data-date{
      font-size: 15px;
    }
  }
  .pin-data-actions{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding:12px 0;
    border-top:1px solid $seperator-grey;
    border-bottom:1px solid $seperator-grey;
    .pin-data-action{
      position: relative;
      .icon{
        width:27px;
        height:27px;
      }
      span{
        font-size: 12px;
      }
      .icon-star-outline{
        &:hover{
          color: #f1c40f;
        }
      }
      .icon-star{
        color: #f1c40f;
      }
      .shared{
        position: absolute;
        top:0;
        left:-50px;
        width:100px;
        text-align: center;
        background-color:#3498db;
        z-index: 999;
        color: #ffffff;
        padding:3px 6px;
        border-radius: 3px;
      }
    }
  }
  .pin-data-desc{
    position: relative;
    height:inherit;
    padding:12px 6px 30px 6px;
    white-space: pre-line;
    text-indent: 21px
    p{
      position: relative;
      height: 100%;
      margin: 0;
      padding-bottom: 49px;
    }
  }
}