.map-layer-img-container{
  user-select: none;
  position: relative;
  width:100%;
  height: 100%;
  touch-action: none;
  background: url('../../assets/img/bg_before_load.jpg') no-repeat center center fixed;
  background-size: cover;
  img{
    transform: translateZ(0);
    position: absolute;
    touch-action: none;
    -webkit-touch-callout: none !important; 
    -webkit-user-select: none !important;
    &.street-names{
      pointer-events:none;
      z-index:996;
    }
  }
}