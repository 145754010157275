.edit-layer-container{
  position: absolute;
  height: 100%;
  width:100%;
  background-color: transparent;
  .edit-overlay{
    position: absolute;
    top:calc(50% - 75px);
    left:calc(50% - 150px);
    width: 300px;
    height: 150px;
    z-index: 999;
    background-color: #ffffff;
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    &.mobile{
      left:0;
      width:100%;
      height:210px;
      top:calc(100% - 210px);
      border-radius: 0;
    }
    .edit-overlay-header{
      position: relative;
      height:47px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 1px solid #c1c1c1;
      .icon-wrapper{
        padding:6px;
        .icon{
          width: 27px;
          height: 27px;
        }
      }
    }
    .edit-overlay-content{
      position: relative;
      height: 100%;
      display: flex;
      padding:6px;
      &.edit-overlay-continue{
        flex-direction: column;
        .button-wrapper{
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top:33px;
          button{
            font-family: 'Raleway', sans-serif;
            background-color: #ED6969;
            border:1px solid #ffffff;
            color: #ffffff;
            font-size: 23px;
            &:hover{
              border:1px solid #ED6969;
              color: #ED6969;
              background-color: rgba(#ffffff,0.8);
            }
            &.yes{
              background-color: #3498db;
              &:hover{
                border:1px solid #3498db;
                color: #3498db;
                background-color: rgba(#ffffff,0.8);
              }
            }
          }
        }
      }
    }
  }
}