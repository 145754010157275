.menu-onboarding-wrapper{
  position: absolute;
  top: 70px;
  left: 93px;
  background-color:#3498db;
  z-index: 999;
  color: #ffffff;
  padding:3px 6px;
  border-radius: 3px;
  animation: bounce 1s infinite alternate;
  -webkit-animation: bounce 1s infinite alternate;
  &:before {
    content: '\25B2';
    position: absolute;
    top:-15px;
    color:#3498db;
  }
  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-3px);
    }
  }
  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-3px);
    }
  }
}