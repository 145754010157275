.sidebar-rectangle {
  position: fixed;
  right: 768px;
  top: 5%;
  transform: translateY(-10%);
  width: 18%;
  min-height: fit-content;
  height: auto;
  background-color: white;
  padding: 12px 21px;
  border: 6px solid #FF45BA;
  color: #c8b7da;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, right 0.3s ease-in-out;
  z-index: 2;
  
  &.visible {
    opacity: 1;
    right: 360px;
  }
  
  .sidebar-link {
    color: #000000;
    text-decoration: none;
    font-size: clamp(0.8rem, 1.5vw, 1.3rem);
    font-family: futura-pt-bold, sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 5px;
    writing-mode: horizontal-tb;
    
    @media (max-width: 768px) {
      font-size: 1.3rem;
      padding: 10px;
    }
  }

  &.mobile {
    @media (max-width: 768px) {
      position: fixed;
      right: 0;
      top: auto;
      bottom: 0;
      width: 100%;
      transform: none;
      z-index: 1000;
    }
  }
}
