$colour-shadow:rgba(0,0,0, 0.125);
$colour-primary:#021b2b;
$colour-accent:#B35BF0;
$colour-accent-alt:#f4c98a;
$colour-background:#2a618b;
$colour-background-mid:rgba(58,133,191,1);
$colour-background-light:#6ba5d2;
$colour-background-alt:#393854;
$colour-text:#f4f4f4;
$colour-text-mid:#b0b0b0;
$colour-text-weak:#678090;
$colour-text-strong:#f4f4f4;
$colour-text-heading:#678090;
$colour-text-invert:	rgba($colour-background, .75);
$colour-text-strong-invert:$colour-background;
$colour-text-link:#F1ECEC;
$colour-text-link-visited:rgba($colour-text-link,.75);
$colour-text-link-hover:	$colour-accent;
$colour-border:$colour-background-light;
$colour-success:#27ae60;
$colour-submit:#4ba0b3;
$colour-error:#e74c3c;
$colour-text-disabled:#2f3640;

$gcht-red:#e5193d;
$icon-black:#262426;
$seperator-grey:#c1c1c1;